import {Company} from '@cmp/shared/models/branding.model';
import {Environment} from './environment.model';

export const base: Environment = {
  company: Company.Kapitol,
  name: 'base',
  production: false,
  apiBaseUrl: 'http://127.0.0.1:8000',
  loginUrl: 'https://tauth.kapitol.cz/auth/realms/kompas/protocol/openid-connect/auth',
  logoutUrl: 'https://tauth.kapitol.cz/auth/realms/kompas/protocol/openid-connect/logout',
  tokenUrl: 'https://tauth.kapitol.cz/auth/realms/kompas/protocol/openid-connect/token',
  clientId: 'newMax',
  clientSecret: '66a17df5-2d89-4950-839f-a599f944d9e5',
  resourceAccess: 'comparator',
  tokenUserIdField: 'sub',
  gtmId: '',
};
