import {base} from './environment.base.kapitol';
import {Environment} from './environment.model';

export const environment: Environment = {
  ...base,
  name: 'dev',
  apiBaseUrl: '',
  sentryDataSourceName: 'https://d45aa2648435424eba0f4685042420bc@error.kapitol.cz/4',
  YBugProjectId: '6m0aa2zj6c4s6kdt4jbw',
};
